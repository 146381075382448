import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../footer'
import EarthquakeData from '../earthquakeData'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


class EarthquakeLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.renderCurrentStatus = this.renderCurrentStatus.bind(this)
    }

    renderCurrentStatus(){
        let pastDayQuakes = 0
        let warningColor = "rgb(141, 171, 127, 0.81)"
        let maxMagnitude = 1
        const oneday = 60 * 60 * 24 * 1000 
        const today = new Date()
        this.props.eqData.forEach((item) => {
            const dayAgo = today - oneday 
            if( item.properties.time > dayAgo ) {
                pastDayQuakes++
                if( item.properties.mag > maxMagnitude){
                    maxMagnitude = item.properties.mag
                }
            }
        })

        if(maxMagnitude >= 4){
            warningColor = "rgb(184, 28, 28, 0.91)"
            // show red status
        }else if(maxMagnitude >= 3){
            warningColor = "rgb(235, 143, 31, 0.91)"
            // show orange status
        }else if(maxMagnitude >=2){
            warningColor = "rgb(224, 232, 75, 0.91)"
            // show yellow status
        }
        
        return(
            <Row style={{padding:"25px", border: '4px solid', borderColor:warningColor, backgroundColor: "rgb(141, 171, 127, 0.81)"}}>
                <h4 style={{border:"solid 1px white", padding: "5px"}}> Today is:  {today.toString()} </h4>
                {pastDayQuakes > 0 ? <h5>Earthquakes in the past 24 hours: {pastDayQuakes}</h5> : <></>}             
            </Row>
        )
    }

    render() {
        return (
        <div style={{ backgroundColor: '#1E2019' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
                    integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
                    crossorigin=""/>
                <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
                    integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
                    crossorigin=""></script>
            </Helmet>
                <Container style={{backgroundColor:"rgb(62 70 54)"}}>
                    <main>
                        <Row style={{padding:"25px"}}><h1>Los Angeles Earthquake Data</h1></Row>
                        <Row>
                            <p>This page utilizes Earthquake data provided by 
                                <a href="https://earthquake.usgs.gov/fdsnws/event/1/"> usgs.gov</a> web services. Data points shown here have
                                occurred within 200Km of Los Angeles. By default the page will show the 10 most recent
                                events that have occured in the past 15 days, and only show events with a magnitude greater
                                than 1.5

                            </p>
                        </Row>
                        {this.renderCurrentStatus()} 
                        <EarthquakeData eqData={this.props.eqData} hasError={this.props.hasError}></EarthquakeData>
                    </main>
                </Container>
            <Footer />
        </div>
        )
    }
}

export default EarthquakeLayout
