import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'
import Table from 'react-bootstrap/Table'
import React, { Component } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './style.scss'


class EarthquakeData extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.renderEarthquakeList = this.renderEarthquakeList.bind(this)
        this.renderMap = this.renderMap.bind(this)
    }

    renderMap(){
        return(
            <div style={{height:"90%"}}>
                <MapContainer  center={[34.06,-118.16]} zoom={6} scrollWheelZoom={true}
                    style={{ height:"90%", position:"inherit", }}
                >
                {this.props.eqData.map((feature, index) => (
                  <Marker key={index} position={[ feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}>
                    <Popup >
                        <h3 >#{ index +1 }</h3>
                        <h4>{feature.properties.mag + " Magnitude"}</h4>
                        <h4>{feature.properties.place}</h4>
                        <h4>{new Date(feature.properties.time).toString()}</h4>
                    </Popup>
                  </Marker>

                ))}
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
            </div>
           
        )
    }
    
    renderEarthquakeList(){
        return(
            <Table bordered variant="dark">
                 <thead>
                    <tr>
                        <th>#</th>
                        <th>Time</th>
                        <th>Magnitude</th>
                        <th>Place</th>
                        <th>Status</th>
                    </tr>
                </thead>
                {this.renderDataRows()}
            </Table>
        )
    }

    renderDataRows(){
        return(
            <tbody>
                {this.props.eqData.map((feature, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{new Date(feature.properties.time).toString()}</td>
                        <td>{feature.properties.mag}</td>
                        <td>{feature.properties.place}</td>
                        <td>{feature.properties.status}</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    render() {
        if(this.props.hasError == true){
            return (
                <Row> 
                    <p style={{color: "rgb(255, 0, 0, 0.7)"}}>
                        An error occurred while attempting to loading data from https://earthquake.usgs.gov . 
                        Please refresh the page to try again. If the error persists, there may be an
                        issue with the API maintained by usgs.gov.
                    </p> 
                </Row>
            )
        }else if(!this.props.eqData.length){
            return(
                <div style={{ position:'relative', textAlign:'center'}}>
                    <img src="/img/fidget-spinner.gif"  />
                    <p>Loading Data...</p>
                </div>
            ) 
        }else{
            return(
                <>
                    <Row id="scrollToMap" >
                        <Link to={'#map'}>
                            <Button
                                variant="success"
                                style={{ margin: '5px', padding: '5px' }}
                                size="lg"
                                aria-label={"Scroll to map"}
                            >
                                Scroll to Map 
                            </Button>
                        </Link>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={6} xxl={6} style={{backgroundColor:"rgb(141, 171, 127, 0.81)"}} >
                            {this.renderEarthquakeList()}
                        </Col>
                        <Col id={"map"} sm={12} md={12} lg={6} xl={6} xxl={6} style={{position: "static", height:"500px"}} >
                            {this.renderMap()}
                        </Col>
                    </Row>
                </>
                
            )
        }
    }
}

export default EarthquakeData
