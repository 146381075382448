import React, { useState, useEffect } from "react"
import EarthquakeLayout from '../../components/earthquake/earthquakeLayout'


const Earthquake = () => {
    const [eqData, setEqData] = useState([]);
    const [hasError, setHasError] = useState([false]);

    let date = new Date();
    date.setDate(date.getDate()-15);
    // date for past 15 days
    date = date.toISOString()

    useEffect(() => {
        const requestUrl = `https://earthquake.usgs.gov/fdsnws/event/1/query?latitude=34.020728&longitude=-118.692602&maxradiuskm=200&minmagnitude=1.5&format=geojson&starttime=` + date
        fetch(requestUrl)
          .then(response => {
            if( response.status != 200){
              setHasError(true)
            }
            return response.json()
          }).catch(error => {
            console.log("error occured calling usgs api : " + error)
          })
          .then(resultData => {
            if(resultData.features.length > 10 ){
              setEqData(resultData.features.slice(0,10))
            }else{
              setEqData(resultData.features)
            }
        }).catch(error => {
          return error
        })
    }, [])
    
    return (
        <>
            {typeof window !== 'undefined' && EarthquakeLayout && <EarthquakeLayout
            title={ "Los Angeles Earthquakes"}
            hasError={hasError}
            eqData={eqData}>
          </EarthquakeLayout>}
        </>
        
    );
};
  
export default Earthquake
